// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalArea {
  padding-left: 12px;
}
.personalArea p {
  color: #FFF;
  font-size: 10px;
  margin-top: 18px;
}
.personalArea__edit {
  display: flex;
  justify-content: space-between;
  max-width: 170px;
  align-items: center;
}
.personalArea__edit a {
  width: 5%;
}
.personalArea__edit img {
  margin-top: 17px;
}`, "",{"version":3,"sources":["webpack://./src/components/PersonalArea/PersonalArea.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,SAAA;AAAN;AAGI;EACE,gBAAA;AADN","sourcesContent":[".personalArea {\n  padding-left: 12px;\n\n  p {\n    color: #FFF;\n    font-size: 10px;\n    margin-top: 18px;\n  }\n\n  &__edit {\n    display: flex;\n    justify-content: space-between;\n    max-width: 170px;\n    align-items: center;\n\n    a {\n      width: 5%;\n    }\n\n    img {\n      margin-top: 17px;\n    }\n\n    &_name {\n\n    }\n  }\n\n  &__lastname {\n\n  }\n\n  &__phone {\n\n  }\n\n  &__gmail {\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
