// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/PersonalArea/popupMenu1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalAreaModal {
  width: 414px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}
.personalAreaModal__title {
  display: flex;
  align-items: center;
}
.personalAreaModal__title img {
  margin: 10px 0 0 7px;
}
.personalAreaModal__items {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-right: 16px;
  min-width: 198px;
  height: 263px;
  position: fixed;
  opacity: 0;
  right: -217px;
  top: 80px;
  border-radius: 11px 0 0 11px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), lightgray 50%/cover no-repeat;
  background-size: cover;
  transition: 0.5s;
}
.personalAreaModal__items_name {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-left: 10px;
  gap: 13px;
}
.personalAreaModal__items__active {
  right: 0;
  opacity: 1;
}
.personalAreaModal__items h3 {
  color: #FFF;
  font-size: 23px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  margin: 9px 0 1px 0;
}
.personalAreaModal__items_line {
  width: 108%;
  height: 3px;
  background: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/components/PersonalAreaModal/PersonalAreaModal.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AACI;EACE,oBAAA;AACN;AAIE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,UAAA;EACA,aAAA;EACA,SAAA;EACA,4BAAA;EACA,kFAAA;EACA,sBAAA;EACA,gBAAA;AAFJ;AAII;EACE,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;AAFN;AAKI;EACE,QAAA;EACA,UAAA;AAHN;AAMI;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,kBAAA;EACA,mBAAA;AAJN;AAOI;EACE,WAAA;EACA,WAAA;EACA,gBAAA;AALN","sourcesContent":[".personalAreaModal {\n  width: 414px;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 100000;\n\n  &__title {\n    display: flex;\n    align-items: center;\n\n    img {\n      margin: 10px 0 0 7px;\n\n    }\n  }\n\n  &__items {\n    display: flex;\n    flex-direction: column;\n    gap: 3px;\n    padding-right: 16px;\n    min-width: 198px;\n    height: 263px;\n    position: fixed;\n    opacity: 0;\n    right: -217px;\n    top: 80px;\n    border-radius: 11px 0 0 11px;\n    background: url('../../assets/images/PersonalArea/popupMenu1.png'), lightgray 50% / cover no-repeat;\n    background-size: cover;\n    transition: .5s;\n\n    &_name {\n      display: flex;\n      align-items: baseline;\n      justify-content: center;\n      padding-left: 10px;\n      gap: 13px;\n    }\n\n    &__active {\n      right: 0;\n      opacity: 1;\n    }\n\n    h3 {\n      color: #FFF;\n      font-size: 23px;\n      font-weight: 400;\n      text-transform: capitalize;\n      text-align: center;\n      margin: 9px 0 1px 0;\n    }\n\n    &_line {\n      width: 108%;\n      height: 3px;\n      background: #FFF;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
