// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/home/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stock {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  padding: 100px 15px 25px 15px;
  height: 896px;
}
.stock h3 {
  color: #fff;
  font-size: 30px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Stock/Stock.scss"],"names":[],"mappings":"AAAA;EACE,mDAAA;EACA,sBAAA;EACA,6BAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".stock {\n  background: url(\"../../assets/images/home/background.png\");\n  background-size: cover;\n  padding: 100px 15px 25px 15px;\n  height: 896px;\n\n  h3 {\n    color: #fff;\n    font-size: 30px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
