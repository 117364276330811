// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  font-family: Prosto One;
}

a {
  text-decoration: none;
  width: 100%;
  text-align: center;
}

#root {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/index.scss","webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;ACCF;;AAAA;EACE,SAAA;EACA,UAAA;EACA,uBAAA;AAGF;;AAAA;EACE,qBAAA;EACA,WAAA;EACA,kBAAA;AAGF;;AAAA;EACE,aAAA;EACA,uBAAA;AAGF","sourcesContent":["button {\n  cursor: pointer;\n}","@import \"assets/styles/index.scss\";\n\n*{\n  margin: 0;\n  padding: 0;\n  font-family: Prosto One;\n}\n\na {\n  text-decoration: none;\n  width: 100%;\n  text-align: center;\n}\n\n#root {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
