// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalAreaData a {
  text-align: start;
}
.personalAreaData p {
  color: white;
  width: 102%;
  font-size: 15px;
  margin-top: 20px;
  border-bottom: 1px solid;
  padding: 0 0 4px 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/PersonalAreaData/PersonalAreaData.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,wBAAA;EACA,qBAAA;AAFJ","sourcesContent":[".personalAreaData {\n\n  a {\n    text-align: start;\n  }\n\n  p {\n    color: white;\n    width: 102%;\n    font-size: 15px;\n    margin-top: 20px;\n    border-bottom: 1px solid;\n    padding: 0 0 4px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
