// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/hookah/hookah11.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hookahElements {
  width: 100%;
  position: relative;
  margin-top: 29px;
  padding-top: 77px;
}
.hookahElements__subtitle {
  width: calc(100% + 30px);
  height: 77px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -15px;
  top: 0;
}
.hookahElements__subtitle p {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/HookahElement/HookahElements.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACE;EACE,wBAAA;EACA,YAAA;EACA,oEAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;AACJ;AACI;EACE,yBAAA;AACN","sourcesContent":[".hookahElements {\n  width: 100%;\n  position: relative;\n  margin-top: 29px;\n  padding-top: 77px;\n\n  &__subtitle {\n    width: calc(100% + 30px);\n    height: 77px;\n    background: url(\"../../assets/images/hookah/hookah11.gif\") center no-repeat;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    left: -15px;\n    top:0;\n\n    p {\n      text-transform: uppercase;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
