// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/hookah/hookah-still.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/hookah/hookah.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hookah {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top center;
  background-size: cover;
  position: relative;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
}
.hookah:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.7;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center;
  background-size: cover;
  z-index: 0;
}
.hookah__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  z-index: 1;
}
.hookah__container p {
  color: #FFF;
  font-family: Prosto One;
  font-size: 30px;
  font-weight: 400;
}
.hookah__container_title {
  display: block;
  position: relative;
  z-index: 1;
  color: #FFF;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
}
.hookah__container_content {
  max-width: 416px;
  width: 100%;
  min-height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.hookah__container_content p {
  color: #FFF;
  font-size: 30px;
}

@media screen and (max-width: 393px) {
  .hookah__container p {
    font-size: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Hookah/Hookah.scss"],"names":[],"mappings":"AAAA;EACE,8DAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;EACA,SAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,YAAA;EACA,0DAAA;EACA,sBAAA;EACA,UAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;AAAJ;AAEI;EACE,WAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAAN;AAGI;EACE,cAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,eAAA;EACA,0BAAA;EACA,kBAAA;AADN;AAII;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAFN;AAIM;EACE,WAAA;EACA,eAAA;AAFR;;AAWA;EACE;IACE,eAAA;EARF;AACF","sourcesContent":[".hookah {\n  background: url(\"../../assets/images/hookah/hookah-still.png\") top center;\n  background-size: cover;\n  position: relative;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n\n  &:before {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    opacity: 0.7;\n    background: url(\"../../assets/images/hookah/hookah.gif\") center;\n    background-size: cover;\n    z-index: 0;\n  }\n\n  &__container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-size: cover;\n    z-index: 1;\n\n    p {\n      color: #FFF;\n      font-family: Prosto One;\n      font-size: 30px;\n      font-weight: 400;\n    }\n\n    &_title {\n      display: block;\n      position: relative;\n      z-index: 1;\n      color: #FFF;\n      font-size: 30px;\n      text-transform: capitalize;\n      text-align: center;\n    }\n\n    &_content {\n      max-width: 416px;\n      width: 100%;\n      min-height: 77px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      margin-top: 30px;\n\n      p {\n        color: #FFF;\n        font-size: 30px;\n      }\n    }\n  }\n}\n\n\n//media\n\n@media screen and (max-width: 393px) {\n  .hookah__container p {\n    font-size: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
