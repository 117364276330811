// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/season/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.season {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top no-repeat;
  background-size: cover;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
}
.season__container_title {
  color: #FFF;
  font-size: 30px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
}
.season__container_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 29px;
}
.season__container_items_item {
  max-width: 166px;
  width: 100%;
  min-height: 331px;
  flex-shrink: 0;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}
.season__container_items_item_img {
  margin-top: 8px;
}
.season__container_items_item_desc {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 9px;
}
.season__container_items_item_desc p:first-child {
  display: block;
  max-width: 126px;
  width: 100%;
}
.season__container_items_item_desc p {
  color: #FFF;
  font-size: 12px;
  line-height: 128%;
}
.season__container_items_item_price {
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-size: 20px;
  margin-top: 19px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Season/Season.scss"],"names":[],"mappings":"AAAA;EACE,iEAAA;EACA,sBAAA;EACA,4BAAA;EACA,SAAA;AACF;AAGI;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,kBAAA;AADN;AAII;EACE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;AAFN;AAIM;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AAFR;AAIQ;EACE,eAAA;AAFV;AAKQ;EACE,aAAA;EACA,iBAAA;EACA,eAAA;AAHV;AAKU;EACE,cAAA;EACA,gBAAA;EACA,WAAA;AAHZ;AAMU;EACE,WAAA;EACA,eAAA;EACA,iBAAA;AAJZ;AAQQ;EACE,WAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AANV","sourcesContent":[".season {\n  background: url(\"../../assets/images/season/background.png\") top no-repeat;\n  background-size: cover;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n\n  &__container {\n\n    &_title {\n      color: #FFF;\n      font-size: 30px;\n      font-weight: 400;\n      text-transform: capitalize;\n      text-align: center;\n    }\n\n    &_items {\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: center;\n      gap: 20px;\n      margin-top: 29px;\n\n      &_item {\n        max-width: 166px;\n        width: 100%;\n        min-height: 331px;\n        flex-shrink: 0;\n        border-radius: 15px;\n        background: rgba(0, 0, 0, 0.7);\n        display: flex;\n        flex-direction: column;\n        padding: 0 8px;\n\n        &_img {\n          margin-top: 8px;\n        }\n\n        &_desc {\n          display: flex;\n          flex-wrap: nowrap;\n          margin-top: 9px;\n\n          p:first-child {\n            display: block;\n            max-width: 126px;\n            width: 100%;\n          }\n\n          p {\n            color: #FFF;\n            font-size: 12px;\n            line-height: 128%;\n          }\n        }\n\n        &_price {\n          color: #FFF;\n          leading-trim: both;\n          text-edge: cap;\n          font-size: 20px;\n          margin-top: 19px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
