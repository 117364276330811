// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  width: 100%;
}

.container {
  min-height: calc(100vh - 79px);
  margin-top: 79px;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;AAAF;;AAEA;EACE,8BAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACF","sourcesContent":[".app {\n  //max-width: 414px;\n  width: 100%;\n}\n.container{\n  min-height: calc(100vh - 79px);\n  margin-top: 79px;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
