// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalAreaEdit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
}
.personalAreaEdit div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}
.personalAreaEdit div p {
  color: #FFF;
  font-size: 10px;
  margin-left: 15px;
}
.personalAreaEdit div input {
  width: 106.811px;
  height: 18.402px;
  flex-shrink: 0;
  border-radius: 4.929px;
  border: 0.329px solid #474747;
  background: var(--white, #FFF);
  outline: none;
  padding: 0 10px 0 13px;
  margin-left: 5px;
}
.personalAreaEdit button {
  display: inline-flex;
  padding: 6.48px 16.849px;
  justify-content: center;
  align-items: center;
  gap: 3.24px;
  border-radius: 4.86px;
  background: #FFF;
  box-shadow: 0px 1.29607px 9.7205px 0px rgba(0, 0, 0, 0.15);
  color: #000;
  text-align: center;
  font-size: 5.832px;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 30px;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/PersonalAreaEdit/PersonalAreaEdit.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,WAAA;AACJ;AACI;EACE,WAAA;EACA,eAAA;EACA,iBAAA;AACN;AAEI;EACE,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;EACA,6BAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AAAN;AAIE;EACE,oBAAA;EACA,wBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,0DAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;AAFJ","sourcesContent":[".personalAreaEdit {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 3px;\n\n  div {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 15px;\n    width: 100%;\n\n    p {\n      color: #FFF;\n      font-size: 10px;\n      margin-left: 15px;\n    }\n\n    input {\n      width: 106.811px;\n      height: 18.402px;\n      flex-shrink: 0;\n      border-radius: 4.929px;\n      border: 0.329px solid #474747;\n      background: var(--white, #FFF);\n      outline: none;\n      padding: 0 10px 0 13px;\n      margin-left: 5px;\n    }\n  }\n\n  button {\n    display: inline-flex;\n    padding: 6.48px 16.849px;\n    justify-content: center;\n    align-items: center;\n    gap: 3.24px;\n    border-radius: 4.86px;\n    background: #FFF;\n    box-shadow: 0px 1.29607px 9.7205px 0px rgba(0, 0, 0, 0.15);\n    color: #000;\n    text-align: center;\n    font-size: 5.832px;\n    font-weight: 800;\n    text-transform: uppercase;\n    margin-top: 30px;\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
