// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/home/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: cover;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.home__container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}
.home__container_item {
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
  cursor: pointer;
}
.home__container_item a {
  flex: 1 1;
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 350px) {
  .home__container_item a {
    font-size: 27px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.scss"],"names":[],"mappings":"AAAA;EACE,oEAAA;EACA,sBAAA;EACA,4BAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,mBAAA;AACJ;AAEI;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,+EAAA;EACA,mBAAA;EACA,eAAA;AAAN;AAEM;EACE,SAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,0BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAR;;AASA;EACE;IACE,eAAA;EANF;AACF","sourcesContent":[".home {\n  background: url(\"../../assets/images/home/background.png\") center no-repeat;\n  background-size: cover;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: end;\n\n  &__container {\n    display: flex;\n    flex-direction: column;\n    justify-content: end;\n    align-items: center;\n\n\n    &_item {\n      width: 100%;\n      min-height: 70px;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n      border-radius: 10px;\n      background: linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.20);\n      margin-bottom: 20px;\n      cursor: pointer;\n\n      a {\n        flex: 1;\n        color: #FFF;\n        font-size: 30px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n        text-transform: capitalize;\n        height: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n      }\n    }\n  }\n}\n\n\n//media\n\n@media screen and (max-width: 350px) {\n  .home__container_item a {\n    font-size: 27px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
