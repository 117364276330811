// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/menu/PopupMenu.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
}
.menu .menu__container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.5s;
}
.menu .menu__container.menu__container-hidden {
  left: -100vw;
  transition: 0.5s;
}
.menu__items {
  display: flex;
  padding: 9px 38px 9px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 80px;
  gap: 30px;
  border-radius: 0px 10px 10px 0px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), lightgray 50%/cover no-repeat;
}
.menu__items a {
  text-align: start;
}
.menu__items p {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/HeaderMenu/HeaderMenu.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,gBAAA;AACJ;AAEE;EACE,YAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,0BAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,gBAAA;EACA,SAAA;EACA,gCAAA;EACA,kFAAA;AADJ;AAGI;EACE,iBAAA;AADN;AAII;EACE,YAAA;AAFN","sourcesContent":[".menu {\n  display: flex;\n  flex-direction: column;\n  gap: 3px;\n  cursor: pointer;\n\n  .menu__container {\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    left: 0;\n    top: 0;\n    transition: .5s;\n  }\n\n  .menu__container.menu__container-hidden {\n    left: -100vw;\n    transition: .5s;\n  }\n\n  &__items {\n    display: flex;\n    padding: 9px 38px 9px 12px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    position: absolute;\n    top: 0;\n    left: 0;\n    margin-top: 80px;\n    gap: 30px;\n    border-radius: 0px 10px 10px 0px;\n    background: url('../../../assets/images/menu/PopupMenu.png'), lightgray 50% / cover no-repeat;\n\n    a {\n      text-align: start;\n    }\n\n    p {\n      color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
