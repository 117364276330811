import React from 'react';
import './Stock.scss'

const Stock = () => {
    return (
        <div className="stock">
            <h3>Наши Акции</h3>
        </div>
    );
};

export default Stock;
